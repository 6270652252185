import React from 'react'
import { Link } from 'react-router-dom'

export const EnterpriseBundle = () => {
  return (
    <div className='enterprise_node_card_bg' id='price_table'>
            <div className='card enterprise'>
            <div className='card_content enterprise clearfix'>
            <div className='head_price clearfix'>
                <div className='head_content clearfix'>
                <div className='head_bg enterprise'></div>
                <div className='head'>
                    <span>Enterprise</span>
                </div>
                </div>

                <div className='price_tag enterprise clearfix'>
                <span className='price'>
                    <span className='sign'>Contact us</span>
                </span>
                </div>
            </div>

            <div className='feature_list enterprise'>
                <ul>
                <li>
                    <p> Dedicated On-Premise Enterprise grade infrastructure</p>
                </li>
                <li>
                    <p> Premium Support</p>
                </li>
                <li>
                    <p> Bespoke Service to meet your needs</p>
                </li>
                <li>
                    <p>Shuken API</p>
                </li>
                <li>
                    <p> Shuken Studio</p>
                </li>
                </ul>
            </div>
            <div className='price_btn enterprise clearfix'>
                <Link target='_blank' to='https://shuken.io/contact/'>
                <button>contact us</button>
                </Link>
            </div>
            </div>
        </div>
    </div>
)
}
