import React, { useEffect, useRef, useState } from "react";
import { BitcoinMainnetIcon } from "../../../assets/icons/BitcoinMainnetIcon";
import { BitcoinTestnetIcon } from "../../../assets/icons/BitcoinTestnetIcon";

export const ExplorerForm = ({ setTxResults, setLoader, onCancelReq }) => {
  const [numberSection, setNumberSection] = useState(false);
  const [amountSection, setAmountSection] = useState(false);
  const [dateSection, setDateSection] = useState(false);
  const [network, setNetwork] = useState("mainnet");
  const [tags, setTags] = useState("");
  const [size, setSize] = useState(10);
  const [txId, setTxId] = useState("");

  const [vinsNumFrom, setVinsNumFrom] = useState("");
  const [vinsNumTo, setVinsNumTo] = useState("");
  const [voutsNumFrom, setVoutsNumFrom] = useState("");
  const [voutsNumTo, setVoutsNumTo] = useState("");
  const [vinsAmountFrom, setVinsAmountFrom] = useState("");
  const [vinsAmountTo, setVinsAmountTo] = useState("");
  const [voutsAmountFrom, setVoutsAmountFrom] = useState("");
  const [voutsAmountTo, setVoutsAmountTo] = useState("");
  const [txDateFrom, setTxDateFrom] = useState("");
  const [txDateTo, setTxDateTo] = useState("");

  const handleNetworkChange = (e) => {
    setNetwork(e.target.value);
  };

  function handleNumberSection(bool) {
    setNumberSection(!bool);
  }
  function handleAmountSection(bool) {
    setAmountSection(!bool);
  }
  function handleDateSection(bool) {
    setDateSection(!bool);
  }

  function resetTxIdInput() {
    document.getElementById("txIdInput").value = "";
    setTxId("");
  }

  function resetTxTags() {
    const radioButtons = document.querySelectorAll(
      'input[type="radio"][name="tags"]'
    );
    radioButtons.forEach((radio) => {
      radio.checked = false;
    });
    setTags("");
  }

  const controllerRef = useRef();

  async function searchExplorerTransactions() {
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;

    setLoader(true);

    const authToken = localStorage.getItem("auth_token");
    if (true || authToken) {
      // const API_URL = process.env.REACT_APP_API_BASE_URL + "v1/search/transactions/_all";
      const API_URL =
        "https://api-dev.shuken.io" + "/v1/search/transactions/_all/";

      const data = {
        network: network,
        txid: txId,
        tags: tags,
        size: size,
        vins_num_from: vinsNumFrom,
        vins_num_to: vinsNumTo,
        vouts_num_from: voutsNumFrom,
        vouts_num_to: voutsNumTo,
        vins_amount_from: vinsAmountFrom,
        vins_amount_to: vinsAmountTo,
        vouts_amount_from: voutsAmountFrom,
        vouts_amount_to: voutsAmountTo,
        tx_date_from: txDateFrom,
        tx_date_to: txDateTo,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": authToken,
        },
        body: JSON.stringify(data),
        signal: signal,
      };

      try {
        const response = await fetch(API_URL, requestOptions);
        const data = await response.json();
        if (data) setTxResults(data.results);
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
        resetTxTags();
        resetTxIdInput();
      }
    }
  }

  const cancelRequest = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
  };

  useEffect(() => {
    searchExplorerTransactions();
    return () => {
      if (onCancelReq) {
        onCancelReq(cancelRequest);
      }
    };
  }, [onCancelReq]);

  return (
    <div className="p-5 text-xl font-light">
      <div className="flex items-center mb-4">
        <select
          id="network"
          name="network"
          onChange={handleNetworkChange}
          className="font-bold placeholder:italic placeholder:text-slate-400 text-black bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 mr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-sm text-black"
        >
          <option value={"mainnet"}>Mainnet</option>
          <option value={"testnet"}>Testnet</option>
        </select>{" "}
        {network === "mainnet" ? (
          <BitcoinMainnetIcon />
        ) : (
          <BitcoinTestnetIcon />
        )}
      </div>
      <fieldset className="flex flex-col justify-between p-3 rounded-md bg-slate-50">
        <input
          id="txIdInput"
          className="placeholder:italic placeholder:text-slate-400 w-full text-black bg-white border border-slate-300 rounded-md py-4 px-3 mr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-sm text-black"
          placeholder="Search by TXID..."
          type="text"
          name="txid"
          onChange={(e) => setTxId(e.target.value)}
        />
        <button
          className="bg-blue-500 mt-4 hover:bg-blue-700 text-white font-normal text-sm py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={searchExplorerTransactions}
        >
          SEARCH
        </button>
      </fieldset>
      <p className="text-slate-400 mt-4 mb-1 text-center">Advanced Search</p>
      <fieldset className="flex justify-center p-2 xl:p-5 rounded-lg bg-slate-50">
        <fieldset className="px-6 py-4 xl:px-8  bg-slate-700 rounded-lg mr-2">
          <p className="text-slate-400 text-center mb-4">Others</p>
          <div className="flex flex-col">
            <div>
              <div>
                <input
                  onClick={(e) => setTags(e.target.id)}
                  type="radio"
                  name="tags"
                  className="mr-2"
                  id="1to1"
                />
                <label
                  htmlFor="1to1"
                  className="cursor-pointer select-none text-slate-100"
                >
                  1to1
                </label>
              </div>

              <div>
                <input
                  onClick={(e) => setTags(e.target.id)}
                  type="radio"
                  name="tags"
                  className="mr-2"
                  id="basic"
                />
                <label
                  htmlFor="basic"
                  className="cursor-pointer select-none text-slate-100"
                >
                  Basic
                </label>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset className="py-4 px-6  xl:px-12 text-left inline bg-slate-700 rounded-lg">
          <p className="text-slate-400 mb-4 text-center">Coinjoins</p>
          <div>
            <input
              onClick={(e) => setTags(e.target.id)}
              type="radio"
              className="mr-2"
              name="tags"
              id="whirpool"
            />
            <label
              htmlFor="whirpool"
              className="mr-4 mt-2 cursor-pointer select-none text-slate-100"
            >
              Whirpool
            </label>
          </div>
          <div>
            <input
              onClick={(e) => setTags(e.target.id)}
              type="radio"
              className="mr-2"
              name="tags"
              id="stonewall"
            />
            <label
              htmlFor="stonewall"
              className="mr-4  cursor-pointer select-none text-slate-100"
            >
              Stonewall
            </label>
          </div>
          <div>
            <input
              onClick={(e) => setTags(e.target.id)}
              type="radio"
              name="tags"
              className="mr-2"
              id="wasabi"
            />
            <label
              htmlFor="wasabi"
              className="mr-4 cursor-pointer select-none text-slate-100"
            >
              Wasabi
            </label>
          </div>
          <div>
            <input
              onClick={(e) => setTags(e.target.id)}
              type="radio"
              name="tags"
              className="mr-2"
              id="joinmarket"
            />
            <label
              htmlFor="joinmarket"
              className="mr-4 cursor-pointer select-none text-slate-100"
            >
              JoinMarket
            </label>
          </div>
        </fieldset>
      </fieldset>
      <fieldset className="p-3 pb-0 rounded-lg bg-slate-">
        <fieldset className="">
          <legend className="text-slate-400 mb-4 text-center">
            # Displayed Results / Search By
          </legend>
          <select
            id="size"
            name="size"
            onChange={(e) => setSize(e.target.value)}
            className="placeholder:italic placeholder:text-slate-400 text-black bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 mr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-sm text-black"
          >
            <option>10</option>
            <option>20</option>
            <option>100</option>
            <option>200</option>
            <option>1000</option>
          </select>
          <div>
            <div className="mt-3 w-fit">
              <input
                type="checkbox"
                id={"number_inputs_outputs"}
                className="mr-2"
                onClick={() => handleNumberSection(numberSection)}
              />
              <label
                htmlFor={"number_inputs_outputs"}
                className="mr-3 cursor-pointer select-none text-slate-100"
                onClick={() => setNumberSection(numberSection)}
              >
                # Inputs/Outputs
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                name="advanced"
                className="mr-2"
                id="total_amount"
                onClick={() => handleAmountSection(amountSection)}
              />
              <label
                htmlFor="total_amount"
                className="mr-3 cursor-pointer select-none text-slate-100"
                onClick={() => setAmountSection(amountSection)}
              >
                Total Amounts
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                name="advanced"
                className="mr-2"
                id="tx_date"
                onClick={() => handleDateSection(dateSection)}
              />
              <label
                htmlFor="tx_date"
                className="cursor-pointer select-none text-slate-100"
                onClick={() => setDateSection(dateSection)}
              >
                Transaction Date
              </label>
            </div>
            <p className="max-w-[400px] md:max-w-none xl:max-w-[400px]  text-[#ecb380] text-center italic text-md mt-8">
              * This is the free/no logs version | Advanced/Enterprise edition{" "}
              <a
                target="_blank"
                href="https://shuken.io/contact/"
                className="text-[#ecb380] font-semibold underline"
              >
                Contact us
              </a>{" "}
            </p>
          </div>
        </fieldset>
        {numberSection && (
          <section id="number" className="advanced p-5">
            <dl className="">
              <dt className="m-2 text-center text-white">Number of Inputs</dt>
              <dd className="m-2 flex flex-col justify-center xl:flex-row">
                <input
                  onChange={(e) => setVinsNumFrom(e.target.value)}
                  className="m-2 text-black inline placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm w-50"
                  placeholder="From"
                  type="text"
                  name="vins_num_from"
                />
                <input
                  onChange={(e) => setVinsNumTo(e.target.value)}
                  className="m-2 text-black inline placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm w-50"
                  placeholder="To"
                  type="text"
                  name="vins_num_to"
                />
              </dd>
            </dl>
            <dl>
              <dt className="m-2 text-center text-white">Number of Outputs</dt>
              <dd className="m-2 flex flex-col justify-center xl:flex-row">
                <input
                  onChange={(e) => setVoutsNumFrom(e.target.value)}
                  className="m-2 text-black inline placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm w-50"
                  placeholder="From"
                  type="text"
                  name="vouts_num_from"
                />
                <input
                  onChange={(e) => setVoutsNumTo(e.target.value)}
                  className="m-2 text-black inline placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm w-50"
                  placeholder="To"
                  type="text"
                  name="vouts_num_to"
                />
              </dd>
            </dl>
          </section>
        )}
        {amountSection && (
          <section id="amount" className="advanced p-5">
            <dl>
              <dt className="m-2 text-center text-white">
                Total Inputs Amount
              </dt>
              <dd className="m-2 flex flex-col justify-center xl:flex-row">
                <input
                  onChange={(e) => setVinsAmountFrom(e.target.value)}
                  className="m-2 text-black inline placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm w-50"
                  placeholder="From"
                  type="text"
                  name="vins_amount_from"
                />
                <input
                  onChange={(e) => setVinsAmountTo(e.target.value)}
                  className="m-2 text-black inline placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm w-50"
                  placeholder="To"
                  type="text"
                  name="vins_amount_to"
                />
              </dd>
            </dl>
            <dl>
              <dt className="m-2 text-center text-white">
                Total Outputs Amount
              </dt>
              <dd className="m-2 flex flex-col justify-center xl:flex-row">
                <input
                  onChange={(e) => setVoutsAmountFrom(e.target.value)}
                  className="m-2 text-black inline placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm w-50"
                  placeholder="From"
                  type="text"
                  name="vouts_amount_from"
                />
                <input
                  onChange={(e) => setVoutsAmountTo(e.target.value)}
                  className="m-2 text-black inline placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm w-50"
                  placeholder="To"
                  type="text"
                  name="vouts_amount_to"
                />
              </dd>
            </dl>
          </section>
        )}
        {dateSection && (
          <section id="date" className="advanced p-5">
            <dl>
              <dt className="text-white text-center m-2">Transaction Date</dt>
              <dd className="m-2 flex flex-col justify-center xl:flex-row">
                <input
                  onChange={(e) => setTxDateFrom(e.target.value)}
                  className="inline m-2 text-black placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm w-50"
                  type="date"
                  name="tx_date_from"
                  placeholder="YYYY-mm-dd"
                />
                <span className="text-white text-center">To</span>
                <input
                  onChange={(e) => setTxDateTo(e.target.value)}
                  className="inline m-2 text-black placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm w-50"
                  type="date"
                  name="tx_date_to"
                  placeholder="YYYY-mm-dd"
                />
              </dd>
            </dl>
          </section>
        )}
      </fieldset>
    </div>
  );
};
