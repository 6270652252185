import React from 'react'
import { useParams } from 'react-router-dom'
import { useExtendSubscription } from '../hooks/useExtendSubscription'

export const ExtendSubscription = () => {

  const { paymentsUrl, invoiceId, nodeId} = useParams()

  useExtendSubscription(nodeId)
  return (
    <div className='full-screen'>
      <h3 style={{ fontSize: '2vw' }}>30 day Renewal Subscription</h3>
      <iframe
        title='invoice'
        id='btc-invoice'
        src={`https://${paymentsUrl}/i/${invoiceId}`}
        width='100%'
        height='100%'
        style={{ border: 'none' }}
      ></iframe>
    </div>
  )
}
