import { useState } from 'react'

export const useModal = () => {
    const [showModal, setShowModal] = useState(false)

    const handleModalCancel = () => {
        setShowModal(false)
    }
    
      const handleButtonClick = () => {
        setShowModal(true)
    }
    
    return {showModal, handleModalCancel, handleButtonClick} 
}
