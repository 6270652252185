import React, { useState, useEffect, useContext } from 'react'
import '../../css/navbar.css'
import { GlobalStateProviderContext } from '../context/GlobalStateProviderContext'

export function SupportAvailability() {
  const [isAvailable, setIsAvailable] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const { nodesList } = useContext(GlobalStateProviderContext)

  async function proNodesSupportAvailable(nodes) {
    if (nodes && nodes.professional_nodes_count > 0) {
      return true
    }
    return false
  }

  const handleMouseOver = (e) => {
    setShowMessage(true);
    let x =  e.clientX
    let y =  e.clientY
    setPosition({ x, y });
  }

  const handleMouseOut = () => {
    setShowMessage(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      const available = await proNodesSupportAvailable(nodesList)
      setIsAvailable(available)
    }
    fetchData()
  }, [nodesList])

  return (
    <div className='nav_icons_div'>
      {isAvailable ? (
        <a
          href='https://shuken.io/support/'
          target='_blank'
          className='logout-btn'
          rel='noreferrer'
        >
          SUPPORT
        </a>
      ) : (
        <div style={{ display: 'flex' }}>
          {showMessage && (
            <p
              className='support-disabled-message'
              style={{
                top: `${position.y - 60}px`,
                right: `${0}px`,
              }}
            >
              Support is only available for Professional node bundles
            </p>
          )}
          <button
            className='support-disabled'
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            SUPPORT
          </button>
        </div>
      )}
    </div>
  )
}
