import { showAlert } from './functions'

export async function authRequired(){
    const authToken = localStorage.getItem("auth_token")
    if (authToken === undefined || authToken == null) {
        showAlert('AUTH REQUIRED');
        window.location.replace("/account/login");
    } 
}

export  async function authenticate(e, email, urlRedirect) {
    e.preventDefault()
    const url = process.env.REACT_APP_API_BASE_URL + '/v1/emails/magic-link/tx/';
    const data = {
        email: email,
        target_url: window.location.href,
        return_uri: urlRedirect ?? '/account/',
    };
    const requestOptions = {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    };
    try {
        const response = await fetch(url, requestOptions);
        const result = await response.json();
    } catch (error) {
        console.error('Error:', error);
    }
}