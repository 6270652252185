import { showAlert } from '../functions'
import { getNodes } from '../nodes/nodes_crud'
import { getInvoices } from './invoices_data'

export function isInvoiceExpired(invoiceExpirationLocalStorageStr) {
  const expirationTime = parseInt(
    window.localStorage.getItem(invoiceExpirationLocalStorageStr),
    10
  )
  
  const now = new Date().getTime()
  const timeDifference = expirationTime - now
  if (now >= expirationTime) {
    window.localStorage.removeItem(invoiceExpirationLocalStorageStr)
  }
  return timeDifference
} 

export function isRenewalInvoiceExpired(invoiceExpirationLocalStorageStr) {
  const expirationTime = parseInt(
    window.localStorage.getItem(invoiceExpirationLocalStorageStr),
    10
  )
  
  const now = new Date().getTime()
  const timeDifference = expirationTime - now
  if (now >= expirationTime) {
    window.localStorage.removeItem(invoiceExpirationLocalStorageStr)
    window.location.reload()
  }
  return timeDifference
}

 
export function checkValidInvoiceStatus(lastInvoice) {
  const validStatuses = ['New', 'New (paidPartial)']

  if (lastInvoice && validStatuses.indexOf(lastInvoice.status) >= 0) {
    setInterval(async () => {
      const updatedInvoicesArray = await getInvoices()
      const updatedLastInvoice = updatedInvoicesArray[0]
      if (
        updatedLastInvoice &&
        updatedLastInvoice.status !== lastInvoice.status
      ) {
        window.location.reload()
      }
    }, 5500)
  }
}

export function handleRenewInvoices(
  data,
  invoiceExpirationLocalStorageStr,
  nodeId
) {
  const invoiceExpirationLocalStorage = localStorage.getItem(
    invoiceExpirationLocalStorageStr
  )
  const invoiceExpireTime = isRenewalInvoiceExpired(invoiceExpirationLocalStorageStr)
  const lastInvoice = data ? data[0] : ''
  if (invoiceExpirationLocalStorage) {
    setTimeout(() => {
      isRenewalInvoiceExpired(invoiceExpirationLocalStorageStr)
    }, invoiceExpireTime)
  }

  checkValidRenewInvoiceStatus(lastInvoice, nodeId)
}

async function checkValidRenewInvoiceStatus(lastInvoice, nodeId) {
  const validStatuses = ['New', 'New (paidPartial)']
  if (
    lastInvoice &&
    validStatuses.indexOf(lastInvoice.btcpay_invoice_status) >= 0
  ) {
    setInterval(async () => {
      const nodesList = await getNodes()
      const node = nodesList.professional_nodes.find(
        (node) => node.user === nodeId
      )

      if (node) {
        const updatedLastInvoice = node.invoices[0]
        if (
          updatedLastInvoice &&
          updatedLastInvoice.btcpay_invoice_status !==
            lastInvoice.btcpay_invoice_status
        ) {
          window.location.reload()
        }
      }
    }, 6200)
  }
}

export function checkRenewalLastInvoice(
  data,
  invoiceExpirationLocalStorageStr
) {
  const lastInvoice = data ? data[0] : ''
  const activeInvoice = isInvoiceActive(lastInvoice)
  let message = ''

  if (
    lastInvoice &&
    activeInvoice &&
    localStorage.getItem(invoiceExpirationLocalStorageStr)
  ) {
    if (lastInvoice.btcpay_invoice_status === 'Processing') {
      message = `Your subscription will be renewed once payment is settled (1 block confirmation)`
      window.location.href = '/account/'
    } else if (lastInvoice.btcpay_invoice_status === 'Settled') {
      message = `Congratulations, your subscription has been renewed!`
      window.location.href = '/account/'
    }
  }
  if (message) {
    localStorage.removeItem(invoiceExpirationLocalStorageStr)
    showAlert(message)
  }
}

function isInvoiceActive(invoice) {

  let dateInUtc = invoice.btcpay_created_at.replace(' ', 'T') + 'Z'
  let localInvoiceDate = new Date(dateInUtc)
  const oneHourAgo = new Date(Date.now() - 60 * 60 * 1000)

  if (localInvoiceDate > oneHourAgo) {
    return true
  } else {
    return false
  }
}
