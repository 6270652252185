import React from 'react'
import { NodesCard } from './subcomponents/NodesCard'
import { useFetchAccountData } from './hooks/useFetchAccountData'

export const Account = () => {
  const auth_token = localStorage.getItem('auth_token')
  const userData = useFetchAccountData()

  return (
    <div className='account-container'>
      <h2 className='account-title'>Account</h2>
      <hr className='reports-line' />
      <div className='user_data'>
        <p>account email: {userData ? userData.email : 'loading...'} </p>
        <p>created at: {userData ? userData.created_at : 'loading...'}</p>
        <p>auth token: {auth_token}</p>
      </div>
      <section className='nodes-section'>
        <h4>My nodes</h4>
        <NodesCard />
      </section>
    </div>
  )
}
