import React from 'react'
import '../../css/styles.css'
import '../../css/navbar.css'
import { useActivePath } from '../hooks/useActivePath'
import { useUnreadNotificationsCount } from '../hooks/useUnreadNotificationsCount'
import { NavbarLink } from './NavbarLink'
import { authRequired } from '../../functions/login'
import { SupportAvailability } from './SupportAvailability'
import { Modal } from './Modal'
import { useModal } from '../hooks/useModal'
import { useNavbar } from '../hooks/useNavbar'

export const NavbarDesktop = () => {
  const authToken = localStorage.getItem('auth_token')
  const active = useActivePath()
  const { showModal, handleModalCancel, handleButtonClick } = useModal()
  const { toggleDisplaySubmenu, handleClickAccountNavbar, isSubmenuVisible } = useNavbar()
  const numberOfNotifications = useUnreadNotificationsCount()
  const handleModalConfirm = () => {
    window.localStorage.removeItem('auth_token')
    window.location.replace('/account/login')
  }

  return (
    <nav className='nav desktop-nav'>
      {showModal && (
        <Modal
          message={`Are you sure you want to log out?`}
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
        />
      )}

      <NavbarLink
        id='nodes-page'
        to='/'
        onClick={handleClickAccountNavbar}
      >
        <div className='menu_big_title'>HOME</div>
      </NavbarLink>
      <NavbarLink
        id=''
        to='/explorer'
        onClick={handleClickAccountNavbar}
      >
        <div className='menu_big_title'>EXPLORER</div>
      </NavbarLink>
      <div className='nav_icons_div_account'>
        <div
          className='account_div'
          onClick={authToken ? toggleDisplaySubmenu : authRequired}
        >
          <button className={`navbar-highlight ${active}`}>ACCOUNT</button>
          {numberOfNotifications > 0 && (
            <span className='unopened-notification-account'></span>
          )}
        </div>
        <div id='subcomponent' className={`subcomponent_account ${isSubmenuVisible}`}>
          <NavbarLink
            to={authToken ? '/account/' : '/account/login'}
            alt='Nodes'
            onClick={handleClickAccountNavbar}
          >
            MY NODES
          </NavbarLink>
          <NavbarLink
            to={authToken ? '/account/notifications' : '/account/login'}
            alt='Nodes'
            onClick={handleClickAccountNavbar}
          >
            NOTIFICATIONS
            {numberOfNotifications > 0 && (
              <span className='unopened-notification-account'></span>
            )}
          </NavbarLink>
          <div className='nav_icons_div'>
            <button onClick={handleButtonClick} className='logout-btn'>
              LOGOUT
            </button>
          </div>
          <SupportAvailability />
        </div>
      </div>
      <NavbarLink
        to='/reports/'
        onClick={handleClickAccountNavbar}
      >
       <div className='menu_big_title'>REPORTS</div>
      </NavbarLink>
    </nav>
  )
}
