import React from 'react'
import btcpay from '../img/btcpayserver-icon.webp'
import professional_logo from '../img/icons8-bitcoin-128.png'
import enterprise_logo from '../img/icons8-mail-100.png'
import community_logo from '../img/icons8-community-100.png'
import '../css/home.css'
import '../css/nodes_cards.css'
import { Link } from 'react-router-dom'

export const Home = () => {

  return (
    <div>
        <h3 className='home_title'>Choose your bundle</h3>
        <div className='home_card_main_div'>
        <Link to={'/communitynode'}>
            <section className='home_card community'>
                <h4>Community Node</h4>
                <span >OUR GIFT TO BITCOIN COMMUNITY</span>
                <div><img src={community_logo} alt="" /></div>
            </section>
        </Link>
        </div>
        <div className='home_card_main_div'>

        <Link to={'/professionalnode'}>
            <section className='home_card professional'>
                <h4>Professional Node</h4>
                <span>FOR ON-CHAIN POWER USERS</span>
                <div><img src={professional_logo} alt="" /></div>
            </section>
        </Link>
        <Link to={'/btcpaybundle'}>
            <section className='home_card btcpay'>
                <h4>BTCPAY Bundle</h4>
                <span >FOR MERCHANTS & SME's</span>
                <div className='btcpay_logo_div'><img src={btcpay} alt="" /></div>
            </section>
        </Link>
        <Link to={'/enterprise'}>
            <section className='home_card enterprise'>
                <h4>Enterprise</h4>
                <span >ENTERPRISE GRADE INFRA & SERVICE</span>
                <div><img src={enterprise_logo} alt="" /></div>
            </section>
        </Link>
        </div>
    </div>
  )
}
