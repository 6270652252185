import { Route, Routes } from 'react-router-dom'
import { Navbar } from './components/main_components/Navbar'
import './components/css/styles.css'
import { Home } from './components/main_components/Home'
import { Reports } from './components/main_components/Reports'
import { Account } from './components/main_components/Account'
import { Checkout } from './components/main_components/Checkout'
import { AccountLogin } from './components/main_components/AccountLogin'
import { AccountNotifications } from './components/main_components/AccountNotifications'
import { Alert } from './components/main_components/subcomponents/Alert'
import { ExtendSubscription } from './components/main_components/subcomponents/ExtendSubscription'
import { ScrollToTop } from './components/main_components/subcomponents/ScroollToTop'
import { GlobalStateProvider } from './components/main_components/context/GlobalStateProvider'
import { useCollectorReq } from './components/main_components/hooks/useCollectorReq'
import { CommunityBundle } from './components/main_components/subcomponents/CommunityBundle'
import { BtcPayBundle } from './components/main_components/subcomponents/BtcPayBundle'
import { ProfessionalBundle } from './components/main_components/subcomponents/ProfessionalBundle'
import { EnterpriseBundle } from './components/main_components/subcomponents/EnterpriseBundle'
import { Explorer } from './components/main_components/Explorer'

function App() {
 
  useCollectorReq()

  return (
    <div className='console-nodes'>
      <GlobalStateProvider>
        <ScrollToTop />
        <Alert />
        <Navbar />
        <div className='right'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/account' element={<Account />} />
            <Route
              path='/account/notifications'
              element={<AccountNotifications />}
            />
            <Route path='/reports' element={<Reports />} />
            <Route path='/checkout/:bundleName' element={<Checkout />} />
            <Route path='/account/login' element={<AccountLogin />} />
            <Route
              path='/checkout/renew/:paymentsUrl/i/:invoiceId/:nodeId'
              element={<ExtendSubscription />}
            />
            <Route path='/communitynode' element={<CommunityBundle />} />
            <Route path='/btcpaybundle' element={<BtcPayBundle />} />
            <Route path='/professionalnode' element={<ProfessionalBundle />} />
            <Route path='/enterprise' element={<EnterpriseBundle />} />

            <Route path='/explorer' element={<Explorer />} />

          </Routes>
        </div>
      </GlobalStateProvider>
    </div>
  )
}

export default App
