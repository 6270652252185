import React from 'react'
import { createCommunityNode } from '../../functions/nodes/nodes_crud'
import { useModal } from '../hooks/useModal'
import { Modal } from './Modal'
import Check from '../../img/check.svg'
import ComingSoon from '../../img/coming-soon.webp'

export const CommunityBundle = () => {
    const { showModal, handleModalCancel, handleButtonClick } = useModal()

    const handleModalConfirm = async () => {
      await createCommunityNode()
      handleModalCancel()
    }
  return (
    <div className='community_node_card_bg' id='price_table'>
    {showModal && (
        <Modal
          message={`A few seconds after confirming you will be redirected to your nodes page\n \n
          A notification (email/console) will be sent once your node is ready!`}
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
        />
    )}
    <div className='card community'>
      <div className='card_content community clearfix'>
        <div className='head_price clearfix'>
          <div className='head_content clearfix'>
            <div className='head_bg community'></div>
            <div className='head community'>
              <span>Community Node</span>
            </div>
          </div>

          <div className='price_tag clearfix'>
            <span className='price'>
              <span className='sign'>Free</span>
            </span>
          </div>
        </div>

        <div className='feature_list community'>
          <ul>
            <li>
              <img className='check_img' src={Check} alt='checked-img' />
              <p>Full node Bitcoin Core (Mainnet & Testnet)</p>
            </li>
            <li>
              <img className='check_img' src={Check} alt='checked-img' />
              <p> Basic Shuken Explorer (Mainnet & Testnet)</p>
            </li>
            <li>
              <img className='check_img' src={Check} alt='checked-img' />
              <p> Xpub Analyzer (100% anonymous/no logs)</p>
            </li>
            <li>
              <img className='check_img' src={Check} alt='checked-img' />
              <p>SSL Clearnet</p>
            </li>
            <li>
              <img className='check_img' src={Check} alt='checked-img' />
              <p>RPC & Fulcrum Wallet Connect</p>
            </li>
            <li>
              <img className='coming_soon' src={ComingSoon} alt='checked-img' />
              <p>Online for 8 hours</p>
            </li>
          </ul>
        </div>

        <div className='price_btn community clearfix'>
          <button onClick={handleButtonClick}>Spin up a node</button>
        </div>
      </div>
    </div>
</div>
  )
}
