import React from 'react'
import '../../css/styles.css'
import '../../css/navbar.css'
import { useActivePath } from '../hooks/useActivePath'
import { useUnreadNotificationsCount } from '../hooks/useUnreadNotificationsCount'
import { NavbarLink } from './NavbarLink'
import { authRequired } from '../../functions/login'
import { SupportAvailability } from './SupportAvailability'
import { Modal } from './Modal'
import { useModal } from '../hooks/useModal'
import { useNavbar } from '../hooks/useNavbar'

export const NavbarMobile = ({isCheckboxChecked, handleMobileNavbar}) => {
  const authToken = localStorage.getItem('auth_token')
  const active = useActivePath()
  const { showModal, handleModalCancel, handleButtonClick } = useModal()
  const { toggleDisplaySubmenu, isSubmenuVisible } = useNavbar()
  const numberOfNotifications = useUnreadNotificationsCount()

  const handleModalConfirm = () => {
    window.localStorage.removeItem('auth_token')
    window.location.replace('/account/login')
  }

  return (
    <nav className='nav mobile-nav' id='nav-mobile'>
      {showModal && (
        <Modal
          message={`Are you sure you want to log out?`}
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
        />
      )}
      <ul style={{ display: isCheckboxChecked ? 'flex' : 'none' }}>
        <NavbarLink
          onClick={handleMobileNavbar}
          id='nodes-page'
          to='/'
        >
          HOME
        </NavbarLink>
        <NavbarLink
          onClick={handleMobileNavbar}
          id='nodes-page'
          to='/explorer'
        >
          EXPLORER
        </NavbarLink>
        <div className='nav_icons_div_account'>
          <div
            className='account_div'
            onClick={authToken ? toggleDisplaySubmenu : authRequired}
          >
            <button className={`navbar-highlight ${active}`}>ACCOUNT</button>
            {numberOfNotifications > 0 && (
              <span className='unopened-notification-account'></span>
            )}
          </div>
          <div id='subcomponent-mobile' className={`subcomponent_account_mobile ${isSubmenuVisible}`}>
            <NavbarLink
              onClick={handleMobileNavbar}
              to={authToken ? '/account/' : '/account/login'}
              alt='Nodes'
            >
              MY NODES
            </NavbarLink>
            <NavbarLink
              onClick={handleMobileNavbar}
              to={authToken ? '/account/notifications' : '/account/login'}
              alt='Nodes'
            >
              NOTIFICATIONS
              {numberOfNotifications > 0 && (
                <span className='unopened-notification-account'></span>
              )}
            </NavbarLink>
            <div className='nav_icons_div'>
              <button onClick={handleButtonClick} className='logout-btn'>
                LOGOUT
              </button>
            </div>
            <SupportAvailability />
          </div>
        </div>
        <NavbarLink
          onClick={handleMobileNavbar}
          to='/reports/'
        >
          REPORTS
        </NavbarLink>
      </ul>
    </nav>
  )
}
