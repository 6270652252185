import { useContext, useEffect, useState } from 'react'
import {
  getUserNotificationID,
  readUserNotificationID,
} from '../../functions/account_data'
import { GlobalStateProviderContext } from '../context/GlobalStateProviderContext'
import { useLocation } from 'react-router-dom'

export const useHandleAccountNotifications = () => {
  const [readNotificationIndex, setReadNotificationIndex] = useState([])
  const [userNotifications, setUserNotifications] = useState([])
  const [notification, setNotification] = useState([])
  const [selectedNotificationIndex, setSelectedNotificationIndex] = useState(0)
  const { notificationsData } = useContext(GlobalStateProviderContext)
  const location = useLocation();


  async function handleUserNotificationID(userNotificationID, index) {
    if (!readNotificationIndex.includes(index)) {
      setReadNotificationIndex([...readNotificationIndex, index])
    }

    const notification = await getUserNotificationID(userNotificationID)
    setNotification(notification)
    setSelectedNotificationIndex(index)
    await readUserNotificationID(userNotificationID)
  }

  async function handleUserNotification() {
    if (
      !notificationsData || !notificationsData.notifications ||
       notificationsData.notifications_count === 0
    ) {
      return
    } else {
      setUserNotifications(notificationsData.notifications.slice(0, 15))
      if (location.pathname === '/account/notifications') { 
        const firstNotificationId = notificationsData.notifications[0].notification_id
        handleUserNotificationID(firstNotificationId, 0)
      }
    }
  }

  
  useEffect(() => {
    handleUserNotification()
  }, [notificationsData])

  return {
    userNotifications,
    notification,
    selectedNotificationIndex,
    handleUserNotificationID,
    readNotificationIndex,
    
  }
}
