import { useState } from 'react'
import { authRequired } from '../../functions/login'

export const useNavbar = () => {
    const [isSubmenuVisible, setSubmenuVisible] = useState(false);
    
    const toggleDisplaySubmenu = () => {
        setSubmenuVisible(!isSubmenuVisible);
        authRequired()
    };

    const handleClickAccountNavbar = () => {
        setSubmenuVisible(false);
    };

    return {toggleDisplaySubmenu, handleClickAccountNavbar, isSubmenuVisible}
}
