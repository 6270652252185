import { showAlert } from '../functions'
import { authRequired } from '../login'

export async function generateInvoice(bundle_name, discount_code) {
  let url =
    process.env.REACT_APP_API_BASE_URL + `/v1/invoices/create/${bundle_name}/`
  if (
    localStorage.getItem('auth_token') === undefined &&
    localStorage.getItem('auth_token') == null
  ) {
    showAlert('AUTH REQUIRED')
    window.location.replace('/account/login')
  } else {

    const data = {
      discount_code: discount_code,
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('auth_token'),
      },
      body: JSON.stringify(data)
    }

    try {
      const response = await fetch(url, requestOptions)

      if (response.status === 401) {
        localStorage.removeItem('auth_token')
        authRequired()
      }

      const data = await response.json()
      return data
    } catch (error) {
      showAlert(error)
    }
  }
}

export async function getInvoices() {
  const url = process.env.REACT_APP_API_BASE_URL + '/v1/invoices/list/all/'
  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': localStorage.getItem('auth_token'),
      },
    })
    if (res.status === 401) {
      localStorage.removeItem('auth_token')
    }
    const data = await res.json()
    return data.professional_invoices
  } catch (error) {
    console.error(error)
  }
}

export async function extendSuscriptionNode(nodeID) {
  let url = process.env.REACT_APP_API_BASE_URL + `/v1/invoices/extend/${nodeID}/`
  if (
    localStorage.getItem('auth_token') === undefined ||
    localStorage.getItem('auth_token') == null
  ) {
    showAlert('AUTH REQUIRED')
    window.location.replace('/account/login')
  } else {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('auth_token'),
        },
      })

      const data = await response.json()
      if (data && data.checkout_link) {
        const invoiceId = data.checkout_link.split('//')
        localStorage.setItem(`renew_invoice-${nodeID}`, invoiceId[1])

        const now = new Date()
        const renewalExpirationTime = now.getTime() + 20 * 60 * 1000
        
        window.localStorage.setItem(
          `renewal_invoice_expiration-${nodeID}`,
          renewalExpirationTime
        )
        
        window.location.replace(`/checkout/renew/${invoiceId[1]}/${nodeID}`)
      }

      if (response.status === 401) {
        localStorage.removeItem('auth_token')
      }
    } catch (error) {
      showAlert(error)
    }
  }
}