import { useContext, useEffect } from 'react'
import { GlobalStateProviderContext } from '../context/GlobalStateProviderContext'
import { updateNodeStatus } from '../../functions/nodes/nodes_account'
import { authRequired } from '../../functions/login'

export const useFetchAccountData = () => {
  const { nodesList, userData } = useContext(GlobalStateProviderContext)
  const auth_token = localStorage.getItem('auth_token')

  const deployingStatuses = ['provisioning', 'new', 'syncing', 'deploying', 'dockerizing']
  useEffect(() => {

    const fetchData = async () => {
      if (
        nodesList?.professional_nodes?.length > 0 &&
        deployingStatuses.indexOf(nodesList.professional_nodes[0].status) >= 0
      ) {
        updateNodeStatus(nodesList.professional_nodes[0])
      } else if (
        nodesList?.community_nodes?.length > 0 &&
        deployingStatuses.indexOf(nodesList.community_nodes[0].status) >= 0
      ) {
        updateNodeStatus(nodesList.community_nodes[0])
      } else if (
        nodesList?.professional_basic_nodes?.length > 0 &&
        deployingStatuses.indexOf(nodesList.professional_basic_nodes[0].status) >= 0
      ){
        updateNodeStatus(nodesList.professional_basic_nodes[0])
      }
    }

    fetchData()
  }, [nodesList, userData])

  useEffect(() => {
    authRequired()
  }, [auth_token])

  return userData
}
