
export function getDay(dateTimeString) {
  const dateString = dateTimeString.split(' ')[0]
  const day = dateString.slice(8, 10)

  return day
}
export function getMonth(dateTimeString) {
  const dateString = dateTimeString.split(' ')[0]

  const monthNumber = parseInt(dateString.slice(5, 7), 10)
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const monthName = monthNames[monthNumber - 1]
  return monthName
}
function isUuidV4(uuid) {
  const uuidV4Regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  return uuidV4Regex.test(uuid)
}
export function base64Decode(base64Url) {
  if (isUuidV4(base64Url)) {
    return base64Url
  } else {
    const padding = '=='.slice(0, (4 - (base64Url.length % 4)) % 4)
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/') + padding
    const rawData = atob(base64)
    return rawData
  }
}

export function showAlert(message) {
  localStorage.setItem('alert_message', message)
  const event = new Event('alert_message_updated');
  window.dispatchEvent(event);
}

