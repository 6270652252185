import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { base64Decode } from '../../functions/functions';

export const useAuthTokenRedirect = () => {
  const [queryParameters] = useSearchParams();
  const urlAuthToken = queryParameters.get('auth_token');
  const urlRedirect = queryParameters.get('return_uri');
  
  useEffect(() => {
    if (urlAuthToken) {
      const authTokenUuid = base64Decode(urlAuthToken);
      localStorage.setItem('auth_token', authTokenUuid);
    }
    if (urlRedirect) {
      window.location.href = urlRedirect;
    }
  }, [urlAuthToken]);
};
