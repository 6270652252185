import React, { useState } from 'react'
import { useAuthRequired } from '../hooks/useAuthRequired'

export const Modal = ({ message, onConfirm, onCancel }) => {
  const [show, setShow] = useState(true)

  const handleCancel = () => {
    setShow(false)
    onCancel()
  }

  const handleConfirm = () => {
    setShow(false)
    onConfirm()
  }

  const lineBreak = (text) => {
    const splitedText = text.split('\n').map((line, index) => {
        if (line) {
            return <React.Fragment key={index}>{line}<br/></React.Fragment>;
        } else {
            return null;
        }
    });
    return splitedText
  }

  useAuthRequired()
  return (
    show && (
      <div className='modal-bg'>
        <div className='modal'>
          <p>{lineBreak(message)}</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1rem',
            }}
          >
            <button className='cancel-button' onClick={handleCancel}>
              Cancel
            </button>
            <button className='accept-button' onClick={handleConfirm}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    )
  )
}
