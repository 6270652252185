export async function getUserData() {
    const authToken = localStorage.getItem('auth_token');
    if (authToken) {
      const API_URL = process.env.REACT_APP_API_BASE_URL + '/v1/users/list/_me/'
      const token = {
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': authToken,
        },
      };
        try {
          const response = await fetch(API_URL, token);
          if (response.status === 401) localStorage.removeItem('auth_token')
          
          const data = await response.json();
          if (data) return data
          
        } catch (error) {
          console.error(error);
        }
    }
  }


export async function getUserNotification() {
  const authToken = localStorage.getItem('auth_token');
  if (authToken) {
    const API_URL = process.env.REACT_APP_API_BASE_URL/* '//localhost:5100'  */+ '/v1/notifications/list/_all/'
    const token = {
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': authToken,
      },
    };
      try {
        const response = await fetch(API_URL, token);
        if (response.status === 401) {
          localStorage.removeItem('auth_token')
        }
        const data = await response.json();
        if (data) {
           return data
        }
      } catch (error) {
        console.error(error);
      }
  }
}

export async function getUserNotificationID(notificationID) {
  const authToken = localStorage.getItem('auth_token');
  if (authToken) {
    const API_URL = process.env.REACT_APP_API_BASE_URL + `/v1/notifications/list/${notificationID}/`
    const token = {
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': authToken,
      },
    };
      try {
        const response = await fetch(API_URL, token); 
        if (response.status === 401) {
          localStorage.removeItem('auth_token')
        }
        const data = await response.json();
        if (data) {
           return data
        }
      } catch (error) {
        console.error(error);
      }
  }
}

export async function readUserNotificationID(notificationID) {
  const authToken = localStorage.getItem('auth_token');
  if (authToken) {
    const API_URL = process.env.REACT_APP_API_BASE_URL/* '//localhost:5100' */ + `/v1/notifications/update/${notificationID}/`
    const token = {
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': authToken,
      },
    };
      try {
        const response = await fetch(API_URL, token); 
        if (response.status === 401) {
          localStorage.removeItem('auth_token')
        }
        const data = await response.json();
      } catch (error) {
        console.error(error);
      }
  }
}  