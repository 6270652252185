import { useState, useEffect } from 'react'
import { GlobalStateProviderContext } from './GlobalStateProviderContext'
import { useLocation } from 'react-router-dom'
import { getNodes } from '../../functions/nodes/nodes_crud'
import { getUserData, getUserNotification } from '../../functions/account_data'

export const GlobalStateProvider = ({ children }) => {
  const [prevPathname, setPrevPathname] = useState(null)
  const [nodesList, setNodesList] = useState(null)
  const [notificationsData, setNotifications] = useState(null)
  const [userData, setUserData] = useState([])

  const location = useLocation()

  useEffect(() => {
    const handleLocationChange = () => {
      setPrevPathname(location.pathname)
    }

    return () => {
      handleLocationChange()
    }
  }, [location.pathname])

  useEffect(() => {
    const fetchNodes = async () => {
      const nodes = await getNodes()
      setNodesList(nodes)
    }

    fetchNodes()
  }, [])
  
  useEffect(() => {
    const fetchNotifications = async () => {
      const notifications = await getUserNotification()
      setNotifications(notifications)
    }

    fetchNotifications()
  }, [])

  useEffect(() => {
    const fetchUserData = async () => {
        const data = await getUserData()
        setUserData(data)
    }

    fetchUserData()
  }, [])

  return (
    <GlobalStateProviderContext.Provider
      value={{ prevPathname, nodesList, notificationsData, userData }}
    >
      {children}
    </GlobalStateProviderContext.Provider>
  )
}
