import React, { useEffect, useState } from 'react'

export const Alert = () => {
  const messageLocalStorage = localStorage.getItem('alert_message')
  const [message, setMessage] = useState(
    messageLocalStorage ? messageLocalStorage : null
  )

  function removeAlert() {
    setMessage(null)
    localStorage.removeItem('alert_message')
  }

  useEffect(() => {
    const handleAlertMessageUpdated = () => {
      setMessage(localStorage.getItem('alert_message'))
    }

    window.addEventListener('alert_message_updated', handleAlertMessageUpdated)

    return () => {
      window.removeEventListener(
        'alert_message_updated',
        handleAlertMessageUpdated
      )
    }
  }, [messageLocalStorage])

  const lineBreak = (text) => {
    const splitedText = text.split('\n').map((line, index) => {
      if (line) {
        return (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        )
      } else {
        return null
      }
    })
    return splitedText
  }
  return (
    message && (
      <div className='alert-bg'>
        <div className='alert'>
          <h3>Warning</h3>
          <p> {lineBreak(message)} </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1rem',
            }}
          >
            <button className='accept-button' onClick={removeAlert}>
              Close
            </button>
          </div>
        </div>
      </div>
    )
  )
}
