import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useCollectorReq = () => {
    
  async function collectorReq() {
    const auth_token = localStorage.getItem('auth_token')

    if (auth_token) {
      let body = {
        '@context': 'https://www.w3.org/ns/activitystreams',
        actor: { agent: navigator.userAgent, auth_token: auth_token },
        object: { url: window.location.href },
      }
      try {
        const response = await fetch(
          'https://collector.shuken.io/behaviour/WebViewed',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'X-Auth-Token': '936ec3fc78d77205d979704112933478',
            },
            body: JSON.stringify(body),
          }
        )
        const res = await response.json()
      } catch (error) {
        console.error(error)
      }
    }
  }

  const location = useLocation()

  useEffect(() => {
    collectorReq()
  }, [location.pathname])
}
