import React from 'react'
import '../css/notifications.css'
import { useAuthRequired } from './hooks/useAuthRequired'
import { useHandleAccountNotifications } from './hooks/useHandleAccountNotifications'

export const AccountNotifications = () => {
  useAuthRequired()

  const {
    userNotifications,
    notification,
    selectedNotificationIndex,
    handleUserNotificationID,
    readNotificationIndex,
  } = useHandleAccountNotifications()

  return (
    <div className='notifications-div'>
      <h2 className='notifications-title'>Notifications</h2>
      <hr className='notifications-line' />
      <section className='notifications-section'>
        <ul
          className={`notifications-list ${
            userNotifications.length >= 4 ? 'scroll' : ''
          }`}
        >
          {userNotifications.map((notification, index) => {
            return (
              <li
                onClick={() => {
                  handleUserNotificationID(notification.notification_id, index)
                }}
                key={index}
                className={
                  index === selectedNotificationIndex ? 'selected' : ''
                }
              >
                <div>
                  <div>
                    {notification.is_read !== 0 &&
                    !readNotificationIndex.includes(index) ? (
                      <span className='unopened-notification'></span>
                    ) : (
                      ''
                    )}
                    <h4>{notification.name}</h4>
                  </div>
                  <article>
                    <span className='time'>
                      {notification.created_at.substring(10)}
                    </span>
                    <span className='date'>
                      {notification.created_at.substring(0, 10)}
                    </span>
                  </article>
                </div>
                <p>{notification.subject}</p>
              </li>
            )
          })}
        </ul>
        <article className='notifications-article'>
          {notification && notification.length !== 0 ? (
            <div>
              <h4>{notification.name}</h4>
              <hr />
              {notification.content &&
                notification.content.length > 0 &&
                notification.content
                  .split(/\n/)
                  .filter((p) => p.trim() !== '')
                  .map((paragraph, index) => (
                    <p
                      key={index}
                      className={
                        paragraph.includes('auth_token=') ? 'word-wrap' : ''
                      }
                    >
                      {paragraph}
                    </p>
                  ))}
              <p className='notification-date'>{notification.created_at}</p>
            </div>
          ) : (
            ''
          )}
        </article>
      </section>
    </div>
  )
}
