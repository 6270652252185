import React from 'react'
import '../css/styles.css'
import '../css/navbar.css'
import ShukenTitle from '../img/shuken_title_blue.png'
import { useAuthTokenRedirect } from './hooks/useAuthTokenRedirect'
import { useHandleAccountNotifications } from './hooks/useHandleAccountNotifications'
import { useHandleMobileNavbar } from './hooks/useHandleMobileNavbar'
import { NavbarDesktop } from './subcomponents/NavbarDesktop'
import { NavbarMobile } from './subcomponents/NavbarMobile'
import { Link } from 'react-router-dom'

export const Navbar = () => {
  useAuthTokenRedirect()
  useHandleAccountNotifications()
  const { isCheckboxChecked, handleMobileNavbar } = useHandleMobileNavbar()

  return (
    <div className='left'>
      <div className=''>
        <div className='menu'>
          <div className='shuken-title-div'>
            <Link to={'/'}>
              <img
                className='shuken-title-img'
                src={ShukenTitle}
                alt='shuken title'
              />
            </Link>
            <h2 className='shuken-title'>CONSOLE</h2>
          </div>
          <NavbarDesktop />

          <input
            type='checkbox'
            readOnly
            onClick={handleMobileNavbar}
            checked={isCheckboxChecked}
            id='mobile-nav-toggle'
          />
          <section className='hamburger-menu'>
            <label className='navbar-toggler' htmlFor='mobile-nav-toggle'>
              <span className='bar'></span>
              <span className='bar'></span>
              <span className='bar'></span>
            </label>
          </section>
        </div>
      </div>
      <NavbarMobile
        isCheckboxChecked={isCheckboxChecked}
        handleMobileNavbar={handleMobileNavbar}
      />
    </div>
  )
}
