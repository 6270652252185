import { showAlert } from '../functions'

export async function getNodes() {
  if (localStorage.getItem('auth_token')) {
    const API_URL =
      process.env.REACT_APP_API_BASE_URL +
      '/v1/nodes/list/all/'
    const authToken = localStorage.getItem('auth_token')
    const token = {
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': authToken,
      },
    }
    const response = await fetch(API_URL, token)
    if (response.status === 401) {
      localStorage.removeItem('auth_token')
    }

    const data = await response.json()
    if (data) {
      return data
    }
  }
}

export async function createCommunityNode() {
  const url = process.env.REACT_APP_API_BASE_URL + '/v1/nodes/create/community/'

  if (
    localStorage.getItem('auth_token') === undefined ||
    localStorage.getItem('auth_token') == null
  ) {
    showAlert('AUTH REQUIRED')
    window.location.replace('/account/login')
  } else {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('auth_token'),
        },
      })

      if (response.status === 401) {
        localStorage.removeItem('auth_token')
      } else if (response.status === 429) {
        const responseBody = await response.json()
        showAlert(responseBody.error_message)
      } else {
        window.location.href = '/account/'
      }
    } catch (error) {
      showAlert(error)
    }
  }
}

/* async function createProfessionalNode() {
  let url =
    process.env.REACT_APP_API_BASE_URL + '/v1/nodes/create/professional/'
  if (
    localStorage.getItem('auth_token') === undefined ||
    localStorage.getItem('auth_token') == null
  ) {
    showAlert('AUTH REQUIRED')
    window.location.replace('/account/login')
  } else {
    showAlert(`Professional Node can take up to 15 minutes to be provisioned`)
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('auth_token'),
        },
      })
      window.location.replace('/account/')
      if (response.status === 401) {
        localStorage.removeItem('auth_token')
      }
    } catch (error) {
      showAlert(error)
    }
  }
} */

export async function deleteNode(nodeID) {
  let url = process.env.REACT_APP_API_BASE_URL + `/v1/nodes/destroy/${nodeID}/`
  if (
    localStorage.getItem('auth_token') === undefined ||
    localStorage.getItem('auth_token') == null
  ) {
    showAlert('AUTH REQUIRED')
    window.location.replace('/account/login')
  } else {
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('auth_token'),
        },
      })
      if (response.ok) {
        showAlert('Your node has been destroyed succesfully')
      }
      if (response.status === 401) {
        localStorage.removeItem('auth_token')
      }
    } catch (error) {
      showAlert(error)
    }
  }
}
