import { useState, useEffect, useContext } from 'react'
import {  getUserNotification } from '../../functions/account_data'
import { GlobalStateProviderContext } from '../context/GlobalStateProviderContext'

export const useUnreadNotificationsCount = () => {
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0)
  const {notificationsData} = useContext(GlobalStateProviderContext)
  const authToken = localStorage.getItem('auth_token')

  const updateUnreadNotificationsCount = async () => {
    if (!authToken) {
      setUnreadNotificationsCount(0)
      return
    }
  
    const notificationsData = await getUserNotification()
    if (!notificationsData || !notificationsData.notifications) {
      setUnreadNotificationsCount(0)
      return
    }
  
    const notificationsList = notificationsData.notifications
    let count = 0
    if (notificationsList.length > 0) {
      const lastNotifications = notificationsList.slice(0, 15)
      for (let index = 0; index < lastNotifications.length; index++) {
        if (lastNotifications[index].is_read === null) {
          count += 1
        }
      }
    }
    setUnreadNotificationsCount(() => {
      return count;
    });
  }

  const hasNullReadStatus = notificationsData && notificationsData.notifications && notificationsData.notifications.slice(0, 15).some(notification => notification.is_read === null);

  useEffect(() => {
    if(notificationsData && hasNullReadStatus){
      updateUnreadNotificationsCount()
    }
    
  }, [window.location.pathname, notificationsData]);
  
  return unreadNotificationsCount
}
