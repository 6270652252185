import React from 'react';
import Check from '../../img/check.svg';
import { Link } from 'react-router-dom';
import BTCPay_logo from '../../img/btcpayserver-icon.webp';

export const BtcPayBundle = () => {
  const bundleName = 'BTCPAY + NODE BUNDLE';
  const bundlePrice = 25;

  return (
    <div className='btcpay_node_card_bg' id='price_table'>
      <div className='card'>
        <div className='card_content btcpay active clearfix'>
          <div className='head_price clearfix'>
            <div className='head_content clearfix'>
              <div className='head_bg btcpay'></div>
              <div className='head btcpay'>
                <span>
                  BTCPAY + NODE <br /> BUNDLE
                </span>
              </div>
            </div>

            <div className='price_tag btcpay clearfix'>
              <span className='price'>
                <span className='sign'>$</span>
                <span className='currency'>{bundlePrice}</span>
                <span className='month'>/30 days</span>
              </span>
            </div>
          </div>

          <div className='feature_list btcpay'>
            <ul>
              <div className='btcpay_div'>
                <li>
                  <img className='check_img' src={Check} alt='checked-img' />
                </li>
                <img
                  className='btcpay_logo'
                  src={BTCPay_logo}
                  alt='btcpay-logo'
                />
                <label
                  htmlFor='disabled-checked-checkbox'
                  className='text-sm font-medium text-black-400 dark:text-gray-500'
                >
                  BTCPay Server (Lightning enabled)
                </label>
              </div>
              <li>
                <img className='check_img' src={Check} alt='checked-img' />
                <p> Full node Bitcoin Core (Mainnet & Testnet)</p>
              </li>
              <li>
                <img className='check_img' src={Check} alt='checked-img' />
                <p> Basic Shuken Explorer (Mainnet & Testnet)</p>
              </li>
              <li>
                <img className='check_img' src={Check} alt='checked-img' />
                <p> Xpub Analyzer (100% anonymous/no logs)</p>
              </li>
              <li>
                <img className='check_img' src={Check} alt='checked-img' />
                <p> Dedicated Instance / IP (SSL Clearnet & Onion address)</p>
              </li>
              <li>
                <img className='check_img' src={Check} alt='checked-img' />
                <p> RPC & Fulcrum Wallet Connect</p>
              </li>
              <li>
                <img className='check_img' src={Check} alt='checked-img' />
                <p> RTL support</p>
              </li>
              <li>
                <img className='check_img' src={Check} alt='checked-img' />
                <p> Running 24/7</p>
              </li>
            </ul>
          </div>

          <div className='price_btn btcpay clearfix'>
            {/* <Link to={'/checkout/btcpay/'} state={{name: bundleName, price: bundlePrice, bundleRoute: 'professional'}}>
                <button>checkout</button>
              </Link> */}
            <Link target='_blank' to='https://shuken.io/contact/'>
              <button>contact us</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
