import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { showAlert } from '../../functions/functions'

export function useAuthRequired() {
  const navigate = useNavigate()
  const authToken = localStorage.getItem('auth_token')

  useEffect(() => {
    if (authToken === undefined || authToken == null) {
      showAlert('AUTH REQUIRED')
      navigate('/account/login')
    }
  }, [navigate])
}
