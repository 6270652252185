import { useState } from 'react'

export const useHandleMobileNavbar = () => {
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)

    const handleMobileNavbar = () => {
      setIsCheckboxChecked(!isCheckboxChecked)
    }
    return {isCheckboxChecked, handleMobileNavbar}
}
