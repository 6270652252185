import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

export function NavbarLink({ to, children, liClassName, ...rest }) {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    
    if (to === currentPath) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }

  }, [to, location]);
  return (
    <Link
      to={to}
      className={`navbar-highlight ${isActive ? 'active' : ''}`}
      {...rest}
    > 
    <div className='nav_icons_div' >
      <div className={liClassName}>{children}</div>
    </div>
    </Link>
  );
}