import React from 'react'
import { useAuthRequired } from './hooks/useAuthRequired'
export const Reports = () => {

  useAuthRequired()

  return (
    <div className="reports-div">
        <h2 className="reports-title">Reports</h2>
        <hr className='reports-line'/>

        <div className="reports">
            <iframe title='mainnet-blocks' className='reports-iframe' src="https://metabase.shuken.io/public/question/2b834e78-1425-436b-9bb3-17ec650aa9bb" style={{border:'none'}} height="400" ></iframe>
            <iframe title='autotagging' className='reports-iframe' src="https://metabase.shuken.io/public/question/c9d88413-8eb7-4b95-8573-86f777ff4d3f" style={{border:'none'}} height="400" ></iframe>
        </div>
    </div>
  )
}
