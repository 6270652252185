import React from 'react'
import '../../css/account_cards.css'
import { getNodeStatusClass } from '../../functions/nodes/nodes_account'
import { getDay, getMonth } from '../../functions/functions'
import { Modal } from './Modal'
import { useHandleNodesCards } from '../hooks/useHandleNodesCards'
import { Link } from 'react-router-dom'

export const NodesCard = () => {

  const {
    showDeleteModal,
    showExtendModal,
    handleExtendSubscriptionButtonClick,
    handleExtendModalConfirm,
    handleModalCancel,
    handleDeleteModalConfirm,
    nodes,
    getRenewExpirationName,
    getRenewName,
    dnsStatuses
  } = useHandleNodesCards()


  if (nodes) {
    return (
      <article className='mynodes-ul'>
        {nodes.map((node) => (
          <div
            key={node.user}
            className='widget-49'
            style={{
              boxShadow: `0 0 0 2px #fff, 0 0 0 4px #${node.bg_color}`,
            }}
          >
            {showExtendModal && (
              <Modal
                message={`Would you like to extend your subscription for an additional 30 days?`}
                onConfirm={handleExtendModalConfirm}
                onCancel={handleModalCancel}
              />
            )}
            {showDeleteModal && (
              <Modal
                message={`This action is irreversible and once confirmed you will lose access to your node
          and any other info/channels you may have running there. 
          We have no way of accessing your node once deleted, so please do not forget to back up if wanted`}
                onConfirm={handleDeleteModalConfirm}
                onCancel={handleModalCancel}
              />
            )}
            <div className='widget-49-date-wrapper'>
              <div
                className={`widget-49-date-primary ${node.subscription_type}`}
              >
                <span
                  className={`widget-49-date-day ${node.subscription_type}`}
                >
                  {getDay(node.subscription_start_date)}{' '}
                </span>
                <span
                  className={`widget-49-date-month ${node.subscription_type}`}
                >
                  {getMonth(node.subscription_start_date)}
                </span>
              </div>
            </div>
            <div className='widget-49-title-wrapper'>
              <ul className='widget-49-meeting-points'>
                <li className='widget-49-meeting-item'>
                  <p> browser auth(user):</p>
                  <span>{node.credentials.user} </span>
                </li>
                <li className='widget-49-meeting-item'>
                  <p> browser auth (pass):</p>
                  <span>{node.credentials.pass}</span>
                </li>
                <li className='widget-49-meeting-item'>
                  <p> subscription type:</p>
                  <span>{node.subscription_type}</span>
                </li>
                {node.invoices && (
                  <li className='widget-49-meeting-item'>
                    <p>Current Invoice status:</p>
                    <span>
                      {node.invoices[0].btcpay_invoice_status === 'New'
                        ? node.invoices[1].btcpay_invoice_status
                        : node.invoices[0].btcpay_invoice_status}
                    </span>
                  </li>
                )}

                <li className='widget-49-meeting-item'>
                  <p> start date:</p>
                  <span>{node.subscription_start_date}</span>
                </li>
                <li className='widget-49-meeting-item'>
                  <p> end date:</p>
                  <span>{node.subscription_end_date}</span>
                </li>
                <li className={getNodeStatusClass(node.status)}>
                {node.status === 'online'
                    ? dnsStatuses[node.user] === 'online'
                        ? `[${node.status}]`
                        : '[Dns propagating]'
                    : node.status
                        ? `[${node.status}]`
                        : null
                }
                </li>
                {node.subscription_type !== 'community' &&
                  node.status === 'online' && (
                    <li className='buttons-li'>
                      {getRenewExpirationName(node.user) ? (
                        <Link
                          to={`/checkout/renew/${getRenewName(node.user)}/${
                            node.user
                          }`}
                          className='renew-btn'
                        >
                          Extend subscription
                        </Link>
                      ) : (
                        <button
                          onClick={() =>
                            handleExtendSubscriptionButtonClick(node.user)
                          }
                          className='renew-btn'
                        >
                          Extend subscription
                        </button>
                      )}
                      {/* <button onClick={() => handleDeleteButtonClick(node.user)} className='delete-btn'>Delete</button>  */}
                    </li>
                  )}
               {node.credentials.host && (
                  node.btcpay_invoice_status !== 'Processing' && dnsStatuses[node.user] === 'online' && (
                      <li className='widget-49-meeting-action'>
                          <a
                              href={node.credentials.host}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='btn btn-sm btn-flash-border-primary'
                          >
                              Open your node
                          </a>
                      </li>
                  )
                )}
              </ul>
            </div>
          </div>
        ))}
      </article>
    )
  }
}
