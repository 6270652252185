import React, { useContext, useState } from 'react'
import { authenticate } from '../functions/login'
import '../css/login.css'
import { GlobalStateProviderContext } from './context/GlobalStateProviderContext'
export const AccountLogin = () => {
  const [email, setEmail] = useState('')
  const [checkEmailMessage, setCheckEmailMessage] = useState('')
  const {prevPathname} = useContext(GlobalStateProviderContext)

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  return (
    <div className='account-container'>
      <h2 className='account-login-title'>Account Authentication </h2>
      <hr className='reports-line' />
      <article className='login-article'>
        <div className='login-box'>
          <h2>AUTHENTICATION</h2>
          <form autoComplete='on'>
            <div className='user-box'>
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type='email'
                name='email'
                required
              />
              <label className={email ? 'has-value' : ''}>Email</label>
            </div>

            <button
              onClick={(e) => {
                if (validateEmail(email)) {
                  authenticate(e, email, prevPathname)
                  setCheckEmailMessage(`check your email (${email})`)
                  setEmail('')
                } else {
                  setCheckEmailMessage('Invalid email address')
                }
              }}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              MAGIC LINK
            </button>
            {checkEmailMessage ? (
              <p
                className={`check-email-paragraph ${
                  checkEmailMessage === 'Invalid email address' ? 'error' : ''
                }`}
              >
                {' '}
                {checkEmailMessage}{' '}
              </p>
            ) : (
              ''
            )}

            <p className='no-logs-message'>
              {' '}
              Simply remember the email you will use and a Magic Link will be
              sent to your inbox with instructions to get in
            </p>
          </form>
        </div>
      </article>
    </div>
  )
}
