import { useEffect } from 'react'
import {
  checkRenewalLastInvoice,
  handleRenewInvoices,
} from '../../functions/invoices/invoices_functions'
import { useHandleNodesCards } from './useHandleNodesCards'

export const useExtendSubscription = (nodeId) => {
  const { nodes } = useHandleNodesCards()
  
  async function fetchAndHandleRenewInvoices() {
    const invoiceExpirationLocalStorageStr = `renewal_invoice_expiration-${nodeId}`
    if (nodes && nodes.length > 0) {
      const node = nodes.find(node => node.user === nodeId);
      const arrayInvoices = node.invoices
      handleRenewInvoices(arrayInvoices, invoiceExpirationLocalStorageStr, nodeId)
      checkRenewalLastInvoice(arrayInvoices, invoiceExpirationLocalStorageStr)
    }
  }

  useEffect(() => {
    fetchAndHandleRenewInvoices()
  }, [nodes])
}
