import { useState, useEffect } from 'react';

export const useActivePath = () => {
  const [active, setActive] = useState('');

  const currentPath = window.location.pathname;
  useEffect(() => {
    const pathArray = currentPath.split('/');
    const firstEndpoint = pathArray[1];
    if (firstEndpoint === 'account') {
      setActive('active');
    } else {
      setActive('');
    }
  }, [currentPath]);

  return active;
};