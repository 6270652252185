import React from 'react';
import Check from '../../img/check.svg';
import { Link } from 'react-router-dom';

export const ProfessionalBundle = () => {
  const bundleName = 'PROFESSIONAL NODE';
  const bundlePrice = 8;

  return (
    <div className='professional_node_card_bg' id='price_table'>
      <div className='card'>
        <div className='card_content professional active clearfix'>
          <div className='head_price clearfix'>
            <div className='head_content clearfix'>
              <div className='head_bg_professional'></div>
              <div className='head_professional'>
                <span>{bundleName}</span>
              </div>
            </div>

            <div className='price_tag professional clearfix'>
              <span className='price'>
                <span className='sign'>$</span>
                <span className='currency'>{bundlePrice}</span>
                <span className='month'>/30 days</span>
              </span>
            </div>
          </div>

          <div className='feature_list'>
            <ul>
              <li>
                <img className='check_img' src={Check} alt='checked-img' />
                <p> Full node Bitcoin Core (Mainnet & Testnet)</p>
              </li>
              <li>
                <img className='check_img' src={Check} alt='checked-img' />
                <p> Basic Shuken Explorer (Mainnet & Testnet)</p>
              </li>
              <li>
                <img className='check_img' src={Check} alt='checked-img' />
                <p> Xpub Analyzer (100% anonymous/no logs)</p>
              </li>
              <li>
                <img className='check_img' src={Check} alt='checked-img' />
                <p> SSL Clearnet & Onion address</p>
              </li>
              <li>
                <img className='check_img' src={Check} alt='checked-img' />
                <p> RPC & Fulcrum Wallet Connect</p>
              </li>
              <li>
                <img className='check_img' src={Check} alt='checked-img' />
                <p> Running 24/7</p>
              </li>
            </ul>
          </div>

          <div className='price_btn clearfix'>
            {/* <Link to={'/checkout/professional'} state={{name: bundleName, price: bundlePrice, bundleRoute: 'professional-basic'}}>
                <button>checkout</button>
              </Link> */}
            <Link target='_blank' to='https://shuken.io/contact/'>
              <button>contact us</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
