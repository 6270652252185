import React, { useState } from "react";
import "../css/explorer.css";
import bitcoinSearchIconBlack from "../img/bitcoin-search-icon-black.png";
import bitcoinSearchIconWhite from "../img/bitcoin-search-icon-white.png";
import xpubLensIconBlack from "../img/xpub-lens-icon-black.png";
import xpubLensIconWhite from "../img/xpub-lens-icon-white.png";
import { XpubLensForm } from "./subcomponents/XpubLensForm";
import { ExplorerForm } from "./subcomponents/ExplorerForm";
import { Skeleton } from "@nextui-org/skeleton";

export const Explorer = () => {
  const [activeTab, setActiveTab] = useState("search");
  const [transactions, setTransactions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loadingComponentsAmount, setLoadingComponentsAmount] = useState(10);
  const [cancelRequestFunc, setCancelRequestFunc] = useState(null);

  const receiveTransactions = (txData) => {
    setTransactions(txData);
  };

  const handleSearchTab = () => {
    if (activeTab !== "search") setTransactions([]);
    setActiveTab("search");
    setLoadingComponentsAmount(10);
  };

  const handleXpubLensTab = () => {
    if (activeTab !== "xpub-lens") setTransactions([]);
    setActiveTab("xpub-lens");
    setLoadingComponentsAmount(1);

    if (cancelRequestFunc) {
      cancelRequestFunc();
    }
  };


  return (
    <div className="flex flex-col flex-1 justify-center items-center w-full mb-[3rem] xl:flex-row xl:items-start mt-8">
      <div className="w-[95%] xl:w-fit">
        <div className="flex border-x-2 border-t-2 w-fit rounded-md">
          <div
            className={`flex items-center p-2 xl:p-3 border-r cursor-pointer explorer-tab ${
              activeTab === "search" ? "active" : ""
            }`}
            onClick={handleSearchTab}
          >
            <img
              className={`h-auto max-w-[16px] xl:max-w-[25px] object-contain`}
              src={
                activeTab === "search"
                  ? bitcoinSearchIconWhite
                  : bitcoinSearchIconBlack
              }
            />
            <button className={`ml-1 `}>Search</button>
          </div>
          <div
            className={`flex items-center p-2 xl:p-3 cursor-pointer explorer-tab ${
              activeTab === "xpub-lens" ? "active" : ""
            }`}
            onClick={handleXpubLensTab}
          >
            <img
              className="h-auto max-w-[16px] xl:max-w-[25px] object-contain"
              src={
                activeTab === "xpub-lens"
                  ? xpubLensIconWhite
                  : xpubLensIconBlack
              }
            />
            <button className={`ml-1  `}>Xpub Lens</button>
          </div>
        </div>

        <div className="search rounded-lg bg-slate-700 m-auto drop-shadow-xl">
          {activeTab === "search" && (
            <ExplorerForm
              setTxResults={receiveTransactions}
              setLoader={setLoader}
              onCancelReq={setCancelRequestFunc}
            />
          )}
          {activeTab === "xpub-lens" && (
            <XpubLensForm
              setTxResults={receiveTransactions}
              setLoader={setLoader}
            />
          )}
        </div>
      </div>

      <div className="mt-10 xl:mt-0 grid xl:grid-cols-2 justify-start gap-[10px] xl:ml-[20px] h-fit">
        {loader
          ? Array(loadingComponentsAmount)
              .fill(0)
              .map((_, index) => (
                <ul
                  key={index}
                  className="flex flex-col h-fit p-3 text-xs w-[320px] text-[12px] text-left rounded-lg bg-slate-50 drop-shadow-md"
                >
                  <article className="flex items-center">
                    <li className="font-bold p-[0.35rem]">Block:</li>
                    <Skeleton className="h-3 flex-grow mx-2 rounded-lg bg-gray-300 animate-pulse" />
                  </article>
                  <article className="flex items-center">
                    <li className="font-bold p-[0.35rem]">TXID:</li>
                    <Skeleton className="h-3 flex-grow mx-2 rounded-lg bg-gray-300 animate-pulse" />
                  </article>
                  <article className="flex items-center">
                    <li className="font-bold p-[0.35rem]">Transaction Time:</li>
                    <Skeleton className="h-3 flex-grow mx-2 rounded-lg bg-gray-300 animate-pulse" />
                  </article>
                  <article className="flex items-center">
                    <li className="font-bold p-[0.35rem]">Source Wallets:</li>
                    <Skeleton className="h-3 flex-grow mx-2 rounded-lg bg-gray-300 animate-pulse" />
                  </article>
                  <article className="flex items-center">
                    <li className="font-bold p-[0.35rem]">
                      Destination Wallets:
                    </li>
                    <Skeleton className="h-3 flex-grow mx-2 rounded-lg bg-gray-300 animate-pulse" />
                  </article>
                  <article className="flex items-center">
                    <li className="font-bold p-[0.35rem]">Source Amount:</li>
                    <Skeleton className="h-3 flex-grow mx-2 rounded-lg bg-gray-300 animate-pulse" />
                  </article>
                  <article className="flex items-center">
                    <li className="font-bold p-[0.35rem]">
                      Destination Amount:
                    </li>
                    <Skeleton className="h-3 flex-grow mx-2 rounded-lg bg-gray-300 animate-pulse" />
                  </article>
                  <article className="flex items-center">
                    <li className="font-bold p-[0.35rem]">Fees:</li>
                    <Skeleton className="h-3 flex-grow mx-2 rounded-lg bg-gray-300 animate-pulse" />
                  </article>
                  <article className="flex items-center">
                    <li className="font-bold p-[0.35rem]">Tags:</li>
                    <Skeleton className="h-3 flex-grow mx-2 rounded-lg bg-gray-300 animate-pulse" />
                  </article>
                </ul>
              ))
          : transactions && transactions.length > 0
          ? transactions.map((transaction, index) => {
              return (
                <ul
                  key={index}
                  className="flex flex-col h-fit p-3 text-xs w-[320px] text-[12px] text-left rounded-lg bg-slate-50 drop-shadow-md"
                >
                  <div>
                    <li className="p-[0.35rem]">
                      <a
                        target="_blank"
                        href={`https://mempool-mnet.shuken.network/tx/${transaction.txid}`}
                        className="text-sm underline  text-orange-600 float-right"
                      >
                        Details
                      </a>
                    </li>
                    <article className="flex">
                      <li className="font-bold p-[0.35rem]">Block:</li>
                      <li className="p-[0.35rem]">{transaction.block}</li>
                    </article>
                  </div>
                  <article className="flex">
                    {" "}
                    <li className="font-bold p-[0.35rem]">TXID:</li>
                    <li className="p-[0.35rem] break-all">
                      {transaction.txid}
                    </li>
                  </article>
                  <article className="flex">
                    {" "}
                    <li className="font-bold p-[0.35rem]">Transaction Time:</li>
                    <li className="p-[0.35rem]">
                      {transaction.transaction_time}
                    </li>
                  </article>
                  <article className="flex">
                    {" "}
                    <li className="font-bold p-[0.35rem]">Source Wallets:</li>
                    <li className="p-[0.35rem]">{transaction.vins_num}</li>
                  </article>
                  <article className="flex">
                    {" "}
                    <li className="font-bold p-[0.35rem]">
                      Destination Wallets:
                    </li>
                    <li className="p-[0.35rem]">{transaction.vouts_num}</li>
                  </article>
                  <article className="flex">
                    {" "}
                    <li className="font-bold p-[0.35rem]">Source Amount:</li>
                    <li className="p-[0.35rem]">{transaction.vins_amount}</li>
                  </article>
                  <article className="flex">
                    {" "}
                    <li className="font-bold p-[0.35rem]">
                      Destination Amount:
                    </li>
                    <li className="p-[0.35rem]">{transaction.vouts_amount}</li>
                  </article>
                  <article className="flex">
                    {" "}
                    <li className="font-bold p-[0.35rem]">Fees:</li>
                    <li className="p-[0.35rem]">{transaction.fee_amount}</li>
                  </article>
                  <article className="flex">
                    {" "}
                    <li className="font-bold p-[0.35rem]">Tags:</li>
                    <li className="p-[0.35rem]">{transaction.tags}</li>
                  </article>
                </ul>
              );
            })
          : ""}
      </div>
    </div>
  );
};
