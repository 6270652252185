import React, { useState } from "react";
import { BitcoinMainnetIcon } from "../../../assets/icons/BitcoinMainnetIcon";
import { BitcoinTestnetIcon } from "../../../assets/icons/BitcoinTestnetIcon";

export const XpubLensForm = ({ setTxResults, setLoader }) => {
  const [network, setNetwork] = useState("mainnet");
  const [dateSection, setDateSection] = useState(false);

  const handleNetworkChange = (e) => {
    setNetwork(e.target.value);
  };

  function handleDateSection(bool) {
    setDateSection(!bool);
  }

  const [xpub, setXpub] = useState("");
  const [fromValue, setFromValue] = useState(0);
  const [size, setSize] = useState(15);
  const [fromValueChange, setFromValueChange] = useState(0);
  const [sizeChange, setSizeChange] = useState(15);
  const [walletType, setWalletType] = useState("p2wpkh");
  const [txDateFrom, setTxDateFrom] = useState("");
  const [txDateTo, setTxDateTo] = useState("");

  async function searchXpubLens() {
    setLoader(true);
    const API_URL =
      /* process.env.REACT_APP_API_BASE_URL */ "https://api-dev.shuken.io" +
      "/v1/search/transactions/xpub/";

    const data = {
      network: network,
      xpub: xpub,
      from_value: fromValue,
      size: size,
      from_value_change: fromValueChange,
      size_change: sizeChange,
      wallet_type: walletType,
      tx_date_from: txDateFrom,
      tx_date_to: txDateTo,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //"X-Auth-Token": authToken,
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(API_URL, requestOptions);
      const data = await response.json();
      if (data) setTxResults(data.results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  }

  return (
    <div className="p-5 text-xl font-light">
      <div className="flex items-center mb-4">
        <select
          id="network"
          name="network"
          onChange={handleNetworkChange}
          className="font-bold placeholder:italic placeholder:text-slate-400 text-black bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 mr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-sm text-black"
        >
          <option value={"mainnet"}>Mainnet</option>
          <option value={"testnet"}>Testnet</option>
        </select>{" "}
        {network === "mainnet" ? (
          <BitcoinMainnetIcon />
        ) : (
          <BitcoinTestnetIcon />
        )}
      </div>
      <fieldset className="flex flex-col justify-between p-3 rounded-md bg-slate-50">
        <input
          className="placeholder:italic placeholder:text-slate-400 w-full text-black bg-white border border-slate-300 rounded-md py-4 px-3 mr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-sm text-black"
          placeholder="Paste your Xpub..."
          type="text"
          name="txid"
          onChange={(e) => setXpub(e.target.value)}
        />
        <button
          className="bg-blue-500 mt-4 hover:bg-blue-700 text-white font-normal text-sm py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={searchXpubLens}
        >
          SEARCH
        </button>
      </fieldset>
      <p className="text-slate-300 mt-4 mb-1 text-center">Wallet type</p>
      <fieldset className="flex justify-center p-5 rounded-lg ">
        <select
          id="wallet_type"
          name="wallet_type"
          className="placeholder:italic placeholder:text-slate-400 text-black bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 mx-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-sm text-black"
          onChange={(e) => setWalletType(e.target.value)}
        >
          <option value="p2wpkh">SegWit Native (bc1q...)</option>
          <option value="p2pkh">Legacy (1...)</option>
          <option value="p2sh">SegWit (3...)</option>
          <option value="p2tr">Taproot (bc1p...)</option>
          <option value="p2ms">MultiSig</option>
        </select>
      </fieldset>
      <fieldset className="p-3 pb-0 rounded-lg bg-slate-">
        <fieldset className="">
          <legend className="text-slate-300 mb-4 text-center">
            Specify UTXO range to read your wallet
          </legend>
          <p className="text-white flex justify-center">Receive addresses</p>
          <section className="flex flex-col xl:flex-row justify-center">
            <div className="flex flex-col xl:items-center mx-2">
              <legend className="text-slate-300 mb-3 text-center mt-3">
                From UTXO Nº
              </legend>
              <input
                onChange={(e) => setFromValue(Number(e.target.value))}
                type="number"
                id="from"
                name="from"
                value={fromValue}
                placeholder="Enter number"
                className="placeholder:italic placeholder:text-slate-300 text-black bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-sm text-black"
              />
            </div>
            <div className="flex flex-col xl:items-center mx-2">
              <legend className="text-center text-slate-300 mb-3 mt-3">
                Nº of UTXOs to analyze
              </legend>
              <input
                onChange={(e) => setSize(Number(e.target.value))}
                type="number"
                id="size"
                name="size"
                value={size}
                placeholder="Enter number"
                className="placeholder:italic placeholder:text-slate-300 text-black bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-sm text-black"
              />
            </div>
          </section>

          <p className="text-white mt-6 flex justify-center">
            Change addresses
          </p>
          <section className="flex flex-col xl:flex-row justify-center">
            <div className="mx-2 flex flex-col xl:items-center">
              <legend className="text-center text-slate-300 mb-3 mt-3">
                From UTXO Nº
              </legend>
              <input
                onChange={(e) => setFromValueChange(Number(e.target.value))}
                type="number"
                id="from"
                name="from"
                value={fromValueChange}
                placeholder="Enter number"
                className=" placeholder:italic placeholder:text-slate-300 text-black bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-sm text-black"
              />
            </div>
            <div className="mx-2 flex flex-col xl:items-center">
              <legend className="text-center text-slate-300 mb-3 mt-3">
                Nº of UTXOs to analyze
              </legend>
              <input
                onChange={(e) => setSizeChange(Number(e.target.value))}
                type="number"
                id="size"
                name="size"
                value={sizeChange}
                placeholder="Enter number"
                className="placeholder:italic placeholder:text-slate-300 text-black bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-sm text-black"
              />
            </div>
          </section>

          <div className="mt-6">
            <input
              type="checkbox"
              name="advanced"
              className="mr-2"
              id="tx_date"
              onClick={() => handleDateSection(dateSection)}
            />
            <label
              htmlFor="tx_date"
              className="cursor-pointer select-none text-slate-300"
              onClick={() => setDateSection(dateSection)}
            >
              Filter by/Transaction Date
            </label>
            <p className="max-w-[400px] md:max-w-none xl:max-w-[400px] text-[#ecb380] text-center italic text-md mt-8">
              * This is the free/no logs version | Advanced/Enterprise edition{" "}
              <a target="_blank"
                href="https://shuken.io/contact/"
                className="text-[#ecb380] font-semibold underline"
              >
                Contact us
              </a>{" "}
            </p>
          </div>
        </fieldset>

        {dateSection && (
          <section id="date" className="advanced p-5">
            <dl>
              <dd className="flex flex-col xl:flex-row justify-center">
                <input
                  onChange={(e) => setTxDateFrom(e.target.value)}
                  className="inline xl:mr-3 text-black placeholder:italic placeholder:text-slate-300 bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm w-50"
                  type="date"
                  name="tx_date_from"
                  placeholder="YYYY-mm-dd"
                />
                <span className="text-slate-300 text-center mt-3 xl:mt-0">
                  To
                </span>
                <input
                  onChange={(e) => setTxDateTo(e.target.value)}
                  className="inline mt-3 xl:ml-3 text-black placeholder:italic placeholder:text-slate-300 bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm w-50"
                  type="date"
                  name="tx_date_to"
                  placeholder="YYYY-mm-dd"
                />
              </dd>
            </dl>
          </section>
        )}
      </fieldset>
    </div>
  );
};
