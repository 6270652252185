import React from 'react'
import '../css/checkout.css'
import { Modal } from './subcomponents/Modal'
import { useAuthRequired } from './hooks/useAuthRequired'
import { useFetchAndHandleInvoices } from './hooks/useFetchAndHandleInvoices'
import { useModal } from './hooks/useModal'
import { useLocation, useParams } from 'react-router-dom'


export const Checkout = () => {
  useAuthRequired()
  const { bundleName } = useParams()
  const { showModal, handleModalCancel, handleButtonClick } = useModal()
  const {
    invoiceLink,
    buttonDisabled,
    handleModalConfirm,
    invoice,
    discountCode,
    setDiscountCode,
  } = useFetchAndHandleInvoices(handleModalCancel, bundleName)

  const location = useLocation()
  let name = '';
  let price = '';
  let bundleRoute = ''
  if (location.state) {
      ({ name, price, bundleRoute } = location.state);
  }


  return (
    <div className='checkout-professional-container'>
      {showModal && (
        <Modal
          message={`An invoice will be generated, please pay with your wallet of choice before it expires (Mind mempool fees)\n \n
Once paid you will be redirected to your nodes page (account)\n \n
Notifications will be sent too!`}
          onConfirm={() => handleModalConfirm(bundleRoute, discountCode)}
          onCancel={handleModalCancel}
        />
      )}
      {/* <div className='loader-div'>
        <div className='loader'>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div> */}
      <section className='checkout-professional-section'>
        <div className='checkout-left'>
          <h3 className='checkout-title'>BECOME A PRO NOW</h3>
          <ul className='checkout-card'>
            <div>
              <li>
                <h5>SUBSCRIPTION TYPE</h5>
                <h5>PRICE</h5>
              </li>
              <li>
                <p>{name}</p>
                <p>{invoice.discount_value ? (price * (1 - (invoice.discount_value / 100))).toFixed(2) : price} USD</p>
              </li>
            </div>
            <article className='disclaimer-div'>
              <p>
                If paid on-chain, the node will only be created after 1
                confirmation is received
              </p>
            </article>
          </ul>
          <div className='checkout-amount-div'>
            <div className='checkout-discount-div'>
              <p
                className={
                  invoice.discount_message === `Discount code of ${invoice.discount_value}% applied succesfully`
                    ? 'green'
                    : 'red'
                }
              >
                {discountCode !== '' ? invoice.discount_message : ''}
              </p>
              <div>
                <p>Do you have any promo code?</p>
              <input
                type='text'
                value={discountCode}
                placeholder='Enter promo code'
                onChange={(e) => setDiscountCode(e.target.value.toLowerCase())}
                />
              </div>
            </div>
            <button disabled={buttonDisabled} onClick={handleButtonClick}>
              Pay invoice
            </button>
          </div>
        </div>
        <div className='checkout-invoice-div'>
          <iframe
            title='invoice'
            id='btc-invoice'
            src={invoiceLink}
            width='100%'
            height='100%'
            style={{ border: 'none' }}
          ></iframe>
        </div>
      </section>
    </div>
  )
}
