import { useContext, useEffect, useState } from 'react'
import {
  deleteNode
} from '../../functions/nodes/nodes_crud'
import { getAllNodes } from '../../functions/nodes/nodes_account'
import { GlobalStateProviderContext } from '../context/GlobalStateProviderContext'
import { extendSuscriptionNode } from '../../functions/invoices/invoices_data'

export const useHandleNodesCards = () => {
  const [showExtendModal, setShowExtendModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [nodeID, setNodeID] = useState('')
  const [nodes, setNodes] = useState([])
  const [dnsStatuses, setDnsStatuses] = useState({});
  const { nodesList } = useContext(GlobalStateProviderContext)

  const handleDeleteButtonClick = (nodeID) => {
    setNodeID(nodeID)
    setShowDeleteModal(true)
  }
  async function showNodes() {
    const allNodes = getAllNodes(nodesList)
    setNodes(allNodes)
  }

  useEffect(() => {
    showNodes()
  }, [nodesList])

  const handleExtendSubscriptionButtonClick = (nodeID) => {
    setNodeID(nodeID)
    setShowExtendModal(true)
  }

  const handleExtendModalConfirm = async () => {
    if (nodeID) {
      setShowExtendModal(false)
      await extendSuscriptionNode(nodeID)
    }
  }
  const handleDeleteModalConfirm = async () => {
    if (nodeID) {
      await deleteNode(nodeID)
      window.location.reload()
    }
    setShowDeleteModal(false)
  }

  const handleModalCancel = () => {
    setShowExtendModal(false)
    setShowDeleteModal(false)
  }

  function getRenewExpirationName(nodeId) {
    const renewExpirationIndex = `renewal_invoice_expiration-${nodeId}`
    if (localStorage.getItem(renewExpirationIndex)) {
      return renewExpirationIndex
    }
    return null
  }

  function getRenewName(nodeId) {
    const renewInvoiceId = `renew_invoice-${nodeId}`
    if (localStorage.getItem(renewInvoiceId)) {
      return localStorage.getItem(renewInvoiceId)
    }
    return null
  }
  
  const handleDNSLookup = async (domain) => {
    if(!domain || domain === 'undefined') {
      console.error("Invalid domain:", domain);
      return;
    }
    const urlToCheck = `https://${domain}.shuken.network`;

    try {
        const response = await fetch(urlToCheck, {
            method: 'HEAD',
            mode: 'no-cors'
        });
        
        return response.status < 500;
    } catch (error) {
        console.error("Error accessing URL", error);
        return false;
    }
  }

  useEffect(() => {
    nodes?.forEach(node => {
        if (node.status === 'online') {
            handleDNSLookup(node.user).then(isAccessible => {
                setDnsStatuses(prevStatuses => ({
                    ...prevStatuses,
                    [node.user]: isAccessible ? 'online' : 'propagating'
                }));
            }).catch(error => {
                console.error("Error checking DNS status for", node.user, ":", error);
                setDnsStatuses(prevStatuses => ({
                    ...prevStatuses,
                    [node.user]: 'error'
                }));
            });
        } else {
            setDnsStatuses(prevStatuses => ({
                ...prevStatuses,
                [node.name]: node.status || 'unknown'
            }));
        }
    });
  }, [nodes]);

  return {
    showDeleteModal,
    showExtendModal,
    handleExtendSubscriptionButtonClick,
    handleDeleteButtonClick,
    handleExtendModalConfirm,
    handleModalCancel,
    handleDeleteModalConfirm,
    nodes,
    getRenewExpirationName,
    getRenewName,
    dnsStatuses
  }
}
