import { useEffect, useState } from 'react'
import {
  generateInvoice,
  getInvoices,
} from '../../functions/invoices/invoices_data'
import {
  checkValidInvoiceStatus,
  isInvoiceExpired,
} from '../../functions/invoices/invoices_functions'
import { showAlert } from '../../functions/functions'


export const useFetchAndHandleInvoices = (handleModalCancel, localStorageInvoiceBundleName) => {
  const localStorageInvoiceExpirationStr = `${localStorageInvoiceBundleName}_invoice_expiration`
  const localStorageInvoiceStr = `${localStorageInvoiceBundleName}_invoice`

  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [invoices, setInvoices] = useState(null)
  const [timeUntilExpiration, setTimeUntilExpiration] = useState(NaN)
  const [loading, setLoading] = useState(true)
  const [discountCode, setDiscountCode] = useState('')
  const [invoice, setInvoice] = useState({})
  const [invoiceLink, setInvoiceLink] = useState(
    localStorage.getItem(localStorageInvoiceStr)
  )

  async function createInvoice(
    bundle_name,
    invoiceExpirationLocalStorageStr,
    discount_code
  ) {
    const invoice = await generateInvoice(bundle_name, discount_code)

    if (invoice) {
      const now = new Date()
      const expirationTime = now.getTime() + 20 * 60 * 1000

      localStorage.setItem(localStorageInvoiceStr, invoice.checkout_link)
      localStorage.setItem(localStorageInvoiceExpirationStr, expirationTime)
      setInvoiceLink(invoice.checkout_link)
      setInvoice(invoice)
    }

    removeExpirationInvoiceLocalStorage(invoiceExpirationLocalStorageStr)
  }

  const removeExpirationInvoiceLocalStorage = (
    invoiceExpirationLocalStorageStr
  ) => {
    const invoiceExpireTime = isInvoiceExpired(invoiceExpirationLocalStorageStr)

    const invoiceExpirationLocalStorage = window.localStorage.getItem(
      invoiceExpirationLocalStorageStr
    )
    setTimeUntilExpiration(invoiceExpireTime)
    setButtonDisabled(invoiceExpirationLocalStorage !== null)
  }

  function checkLastInvoice(lastInvoice) {
    const invoiceisActive = localStorage.getItem(
      localStorageInvoiceExpirationStr
    )
    let message = ''
  
    if (lastInvoice && invoiceisActive) {
      if (lastInvoice.status === 'Processing') {
        message = `Your sovereignty is in the making.\n \nOnce payment is settled, please be patient (few minutes)  as it can take longer than centralized and privacy invasive services to have your node ready. 
  \n \nNotifications will be sent too during the baking!`
        window.location.href = '/account/'
      } else if (lastInvoice.status === 'Settled') {
        message = `Your sovereignty is in the making, please be patient (few minutes)  as it can take longer than centralized and privacy invasive services to have your node ready. 
  \n \nNotifications will be sent too during the baking!`
        window.location.href = '/account/'
      }
    }
  
    if (message) {
      localStorage.removeItem(localStorageInvoiceExpirationStr)
      showAlert(message)
    }
  }
  const handleModalConfirm = async (bundle_name, discount_code) => {
    handleModalCancel()
    await createInvoice(bundle_name, localStorageInvoiceExpirationStr, discount_code)
    setLoading(false)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      removeExpirationInvoiceLocalStorage(localStorageInvoiceExpirationStr)
    }, timeUntilExpiration)

    return () => clearTimeout(timer)
  }, [timeUntilExpiration])

  useEffect(() => {
    async function fetchAndHandleInvoices() {
      const data = await getInvoices()
      setInvoices(data)
    }
    fetchAndHandleInvoices()
  }, [loading])

  useEffect(() => {
    if (invoices) {
      const lastInvoice = invoices ? invoices[0] : ''
      checkValidInvoiceStatus(lastInvoice)
      checkLastInvoice(lastInvoice)
      setButtonDisabled(
        localStorage.getItem(localStorageInvoiceExpirationStr) !== null
      )
    }
  }, [invoices])

  return {
    invoiceLink,
    buttonDisabled,
    handleModalConfirm,
    invoice,
    discountCode,
    setDiscountCode,
  }
}
