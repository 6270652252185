import { getNodes } from './nodes_crud'

export async function getLastNodeStatus() {
  const nodesList = await getNodes()

  if (nodesList) {
    const allNodes = [
      ...nodesList.community_nodes.map((node) => ({
        ...node,
        subscription_type: 'Community',
      })),
      ...nodesList.professional_nodes.map((node) => ({
        ...node,
        subscription_type: 'Btcpay',
      })),
      ...nodesList.professional_basic_nodes.map((node) => ({
        ...node,
        subscription_type: 'Professional',
      })),
      ...nodesList.enterprise_nodes.map((node) => ({
        ...node,
        subscription_type: 'Enterprise',
      })),
    ]
    allNodes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    const lastNode = allNodes[0]

    return lastNode
  }
}

export function getAllNodes(nodesList) {
  if (nodesList) {
    const allNodes = [
      ...nodesList.community_nodes.map((node) => ({
        ...node,
        subscription_type: 'community',
        credentials:
          node.status === 'online'
            ? {
                user: node.user,
                pass: node.pass,
                host: node.host,
              }
            : {
                user: '',
                pass: '',
                host: '',
              },
      })),
      ...nodesList.professional_nodes.map((node) => ({
        ...node,
        subscription_type: 'btcpay bundle',

        credentials:
          node.status === 'online'
            ? {
                user: node.user,
                pass: node.pass,
                host: node.host,
              }
            : {
                user: '',
                pass: '',
                host: '',
              },
      })),
      ...nodesList.professional_basic_nodes.map((node) => ({
        ...node,
        subscription_type: 'professional',

        credentials:
          node.status === 'online'
            ? {
                user: node.user,
                pass: node.pass,
                host: node.host,
              }
            : {
                user: '',
                pass: '',
                host: '',
              },
      })),
      ...nodesList.enterprise_nodes.map((node) => ({
        ...node,
        subscription_type: 'enterprise',
        credentials:
          node.status === 'online'
            ? {
                user: node.user,
                pass: node.pass,
                host: node.host,
              }
            : {
                user: '',
                pass: '',
                host: '',
              },
      })),
    ]
    allNodes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    return allNodes
  }
}

export function getNodeStatusClass(status) {
  let statusClass = 'node-status'
  if (status === 'online') {
    return (statusClass += ' green')
  } else if (status === 'offline' || status === 'destroyed') {
    return (statusClass += ' red')
  } else {
    return (statusClass += ' blue')
  }
}

export async function updateNodeStatus(lastNode) {
  if (lastNode) {
    setInterval(async () => {
      const lastNodeUpdated = await getLastNodeStatus()
      if (lastNodeUpdated && lastNodeUpdated.status !== lastNode.status) {
        window.location.reload()
      }
    }, 6200)
  }
}
